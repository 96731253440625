// import * as styles from '../../styles/blogPost.module.css';

import { getBlogPostTags, getUrl } from '../util';

import BlogCard from '../components/Card/BlogCard';
import CardGrid from '../containers/CardGrid/CardGrid';
import Layout from '../containers/layout';
import React from 'react';
import { graphql } from 'gatsby';

// import Tag from '../components/Tag/Tag';
// import TagContainer from '../components/Tag/TagContainer';
// import { getTags } from '../util';

const Tags = ({ location, data, pageContext }) => {
  const blogPostsData = data.allStrapiBlogs.nodes;
  const urlArr = data.allSitePage.nodes;
  const DefaultImage =
    data.strapiGeneralSeo.SiteSEO.PreviewImage.formats.medium.url;

  const blogPosts = blogPostsData.map((post, i) => {
    return (
      <BlogCard
        title={post.Title}
        date={post.Date}
        img={post.CoverImage ? post.CoverImage.url : DefaultImage}
        link={getUrl(urlArr, post.id)}
        tags={getBlogPostTags(post)}
        key={i}
      />
    );
  });

  const seoData = {
    MetaTitle: `Blogs with Tag ${pageContext.tag}`,
    MetaDescription: `Blog posts by V3 Distribution with tags ${pageContext.tag}.`,
    Keywords: `${pageContext.tag}, V3 Distribution, blog`,
  };

  return (
    <Layout seoData={seoData} location={location}>
      <h1>Blog posts with tag: {pageContext.tag}</h1>
      <CardGrid show>{blogPosts}</CardGrid>
    </Layout>
  );
};

export const query = graphql`
  query tagPageQuery($id: String!) {
    allStrapiBlogs(filter: { Tags: { regex: $id } }) {
      nodes {
        id
        Author
        Title
        Tags
        Date
        CoverImage {
          url
        }
        published_at
      }
    }
    allSitePage {
      nodes {
        path
        context {
          id
        }
      }
    }
    strapiGeneralSeo {
      SiteSEO {
        PreviewImage {
          formats {
            medium {
              url
              name
            }
          }
        }
      }
    }
  }
`;

export default Tags;
